@import '../../styles/colors';

.login {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: $color-secondary;

  &_container {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 1fr 40px;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    border-radius: 10px !important;
    width: 800px;
    min-width: 600px;
    max-width: 800px;
    height: 360px;

    &_access {
      height: 100%;
      display: flex;
      // flex-direction: column;
      align-items: center;
      padding-left: 40px;

      &_form {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        padding-top: 10px;
        align-items: center;
        &_button {
          text-align: right;
        }
      }
    }

    &_title {
      padding-right: 20px;
      padding-bottom: 40px;
      text-align: center;
      &_logo {
        width: 70%;
      }
      &_text {
        font-size: 18px;
        margin-top: 10px;
        &_divider {
          border-right: 2px solid $color-normal;
          height: 100%;
          padding-left: 5px;
          margin-right: 5px;
        }
      }
    }

    &_footer {
      background-color: $color-light;
      grid-column: 1 / 3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-radius: 0 0 10px 10px !important;
      height: 100%;

      font-size: 14px;
      color: $color-normal;

      &_recovery {
        // display: flex;
      }

      &-link {
        text-decoration: none;
        color: $color-secondary;
      }
    }
  }
}
