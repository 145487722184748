@import '../../styles/colors';

.signups {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: $color-secondary !important;

  &_container {
    background-color: $color-white;
    border-radius: 10px !important;
    width: 100%;
    min-width: 600px;
    max-width: 1000px;
    overflow: hidden;
    padding: 20px;

    h3 {
      padding-left: 20px;
    }

    &_form {
      padding: 20px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      &_fields {
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }

      &_bottom {
        margin-top: 20px;
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: flex-end;

        &-buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          button:nth-child(1) {
            margin-right: 15px;
          }

          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  &_success {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    justify-content: center;
    background-color: $color-white;
    border-radius: 10px !important;
    width: 500px;
    max-width: 1000px;
    height: 300px;
    overflow: hidden;

    h1,
    h3 {
      align-self: center;
      justify-self: center;
      text-align: center;
    }

    &-svg {
      place-self: center;
      height: 4rem !important;
      width: 4rem !important;
      color: $color-complementary;
    }

    h1 {
      color: $color-primary;
    }

    &_link {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-column-gap: 20px;
      align-self: center;
      justify-self: center;

      padding-bottom: 32px;

      a {
        align-self: center;
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        margin-right: 10px;
        font-size: x-large;
        text-decoration: none;
        color: $color-complementary;
      }

      svg {
        align-self: center;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
    }
  }
}
